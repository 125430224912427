<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mb-5">
                                <label>{{$t('balance_balances_review.financial_year')}}</label>
                                <multiselect v-model="financial_year"
                                             :placeholder="$t('balance_balances_review.financial_year')"
                                             label="name"
                                             track-by="id"
                                             :options="financial_years_list"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-6 mb-5">
                                <label>{{$t('balance_balances_review.account')}}</label>
                                <treeselect
                                        :options="accounts_list"
                                        :load-options="loadOptions"
                                        :multiple="false"
                                        :value="filters.id"
                                        @input="updateValue"
                                        :searchable="true"
                                        :show-count="true"
                                        :no-children-text="$t('No_sub_options')"
                                        :no-options-text="$t('No_options_available')"
                                        :no-results-text="$t('No_results_found')"
                                        :placeholder="$t('Select')">
                                </treeselect>
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!-- End Filter -->
        <div class="card card-custom">
            <div class="card-body">
                <div class="mt-3">
                    <div class="row mb-10">
                        <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                            <h4 class="text-center">{{ $t('balance_balances_review.balance_balances_review')}}</h4>
                            <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                        </div>

                    </div>
                    <div class="row mb-10">
                        <div class="col-12" v-if="data_report_details">
                            <div class="table-responsive">
                                <div class="row justify-content-end p-4">
                                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                        <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                                            <i class="la la-file-excel"></i>{{$t('excel')}}
                                        </button>
                                        <button class="dropdown-item" @click="printData('summaryTableDetails')">
                                            <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                        </button>
                                    </b-dropdown>
                                </div>

                                <table class="table table-bordered" id="summaryTableDetails">
                                    <thead>
                                    <tr>
                                        <th>{{$t('balance_balances_review.name')}}</th>
                                        <th>{{$t('balance_balances_review.code')}}</th>
                                        <th>{{$t('balance_balances_review.debit')}} ({{currency_name}})</th>
                                        <th>{{$t('balance_balances_review.credit')}} ({{currency_name}})</th>

                                    </tr>
                                    </thead>
                                    <tbody v-html="getHtmlContent()">

                                    </tbody>

                                    <tfoot>
                                    <tr>
                                        <td colspan="2"><b>{{$t('journal_transactions.total')}}</b></td>
                                        <th>{{total_credit ? total_credit : 0}}</th>
                                        <th>{{total_debit ? total_debit : 0}}</th>
                                    </tr>
                                    </tfoot>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end::supplier-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";

    export default {
        name: "index-balance-balances-review",
        data() {
            return {
                mainRoute: '/reports/accounting/account-movements',
                mainRouteDependency: 'base/dependency',

                currency_name: null,


                data_report_details: [],
                details_total_sum: [],
                total_credit: 0,
                total_debit: 0,

                financial_years_list: [],
                accounts_list: [],
                financial_year: null,

                filters: {
                    financial_year_id: null,
                    account_id: null,
                },
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page: 0,
            }
        },
        watch: {
            financial_year: function (val) {
                if (val) {
                    this.filters.financial_year_id = val.id;
                } else {
                    this.filters.financial_year_id = null;
                }
            },
        },
        beforeMount() {
            this.getFinancialYears();
            this.getAccountParents();
            this.getAuthUser();
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.balance_balances_review")}]);
        },
        methods: {
            accountingDetailsExport() {
                let _footer = {
                    label: this.$t('balance_sheet.total') + ` (${this.currency_name})`,
                    amount: 0,
                };
                this.exportData = [];
                this.data_report_details.forEach((row) => {
                    this.customData(row);
                });
                this.exportData.push(_footer);

                return this.exportData;
            },
            customData(data_list) {
                this.exportData.push({
                    label: data_list.label + ` (${this.currency_name})`,
                    amount: (data_list.has_children ? this.$t('balance_sheet.subtotal') + ': ' : '') + data_list.amount,
                });
                if (data_list.has_children) {
                    data_list.children.forEach((row) => {
                        this.customData(row);
                    });
                }
            },
            getRandom() {
                return Math.floor(Math.random() * 10000);
            },
            doFilter() {
                this.getReportDetails();

            },
            resetFilter() {
                this.filters.financial_year_id = null;
                this.filters.id = null;
                this.financial_year = null;

            },
            updateValue(value) {
                this.filters.id = value
            },

            loadOptions() {
            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },
            getFinancialYears(filter = null) {
                // if (filter && filter.length >= 2)
                ApiService.get(this.mainRouteDependency + "/financial_years", {params: {name: filter}}).then((response) => {
                    this.financial_years_list = response.data.data.map((row) => {
                        return {id: row.id, name: row.start_date + ' - ' + row.end_date};
                    });
                });
            },
            getAccountParents() {
                ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
                    this.accounts_list = response.data.data;
                });
            },
            /*****
             * details
             */

            getReportDetails() {
                ApiService.get(this.mainRoute, {params: {...this.filters}}).then((response) => {
                    this.data_report_details = response.data.data.data;
                    this.total_credit = response.data.data.total_credit;
                    this.total_debit = response.data.data.total_debit;
                });
            },
            convertTableToExcelReport(tableId) {
                this.setReportLog('excel', 'balance of balances review');
                TableToExcel.convert(document.getElementById(tableId), {
                    name: this.$t("MENU.balance_balances_review") + '.xlsx',
                    sheet: {
                        name: 'Sheet 1'
                    }
                });
            },
            printData(tableId) {
                this.setReportLog('pdf', 'balance of balances review');

                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            getHtmlContent() {
                let content = '';
                this.data_report_details.forEach((_data) => {
                    content += this.getHtmlContentChild(_data);
                });
                return content;
            },
            getHtmlContentChild(_data) {
                let _margin = "";
                _margin = "padding-left: " + (_data.code ? (_data.code.length * 4) : 0) + 'px';

                let content = "<tr><td style='" + _margin + "'>" + _data.label + "</td>"
                    + "<td>" + _data.code + "</td>"
                    + "<td>" + _data.debit + "</td>"
                    + "<td>" + _data.credit + "</td>"
                    + "</tr>";
                if (_data.has_children) {
                    _data.children.forEach((row) => {
                        content += this.getHtmlContentChild(row);
                    });
                }
                return content;
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
